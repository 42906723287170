import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Container from '@/components/Container';
import { Routes } from '@/enums/routes.enum.js';
import Link from '@/components/Link';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('Page not found.')} />
      <Container className="text-left my-xl-fluid text-lg-fluid">
        <h1>
          <div
            aria-hidden="true"
            className="text-blue-600 text-6xl-fluid dark:text-blue-500"
          >
            404
          </div>
          <Trans>Page not found.</Trans>
        </h1>
        <div>
          <Trans>We could not find the requestet page.</Trans>
        </div>
        <div>
          <Link to={Routes.Home}>
            <Trans>Take me home</Trans>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
  }
`;
